import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import FooterCta from './FooterCta'
import FooterQuotes from './FooterQuotes'
import FooterSubscribe from './FooterSubscribe'
import SiteContact from '../Site/SiteContact'

const Footer = ({ quoteColor, subscribeColor }) => {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          prismicGlobal {
            data {
              contact_address {
                html
              }
              contact_cta
              email_address
              phone_number
              primary_menu {
                link_heading
                link {
                  url
                  uid
                }
              }
              quotes {
                name {
                  html
                }
                quote {
                  html
                }
              }
              social_menu {
                link {
                  url
                }
                link_heading
              }
            }
          }
        }
      `}
      render={data => (
        <div className="leading-tight">
          <div className="md:px-ogs">
            <div className="md:flex md:-ml-gs md:min-h-footer-row">
              <div className="md:w-16/24 pt-5 md:pt-10 lg:pt-15 pb-24 px-ogs md:pr-0 md:pl-gs">
                <FooterCta
                  cta={data.prismicGlobal.data.contact_cta}
                  email={data.prismicGlobal.data.email_address}
                />
              </div>
              <div className="md:w-8/24 md:pl-gs">
                <div
                  className={`pb-10 lg:pb-15 pt-5 md:pt-10 lg:pt-15 md:flush-right h-full ${
                    quoteColor ? 'bg-' + quoteColor : 'bg-grey'
                  }`}
                >
                  <div className="flex md:justify-end px-ogs md:px-0 md:-ml-gs">
                    <div className="md:w-7/8 lg:pl-gs">
                      <FooterQuotes quotes={data.prismicGlobal.data.quotes} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative">
            <div className="absolute inset-0 hidden md:flex">
              <div
                className={`w-1/2 ${
                  subscribeColor ? 'bg-' + subscribeColor : 'bg-green'
                }`}
              ></div>
              <div className="w-1/2 bg-smoke"></div>
            </div>
            <div className="px-ogs md:min-h-footer-row relative">
              <div className="md:flex -ml-gs">
                <div className="md:w-1/2 pl-gs">
                  <div
                    className={`h-full flush-both md:flush-none py-10 lg:py-15 bg-${
                      subscribeColor ? subscribeColor : 'green'
                    }`}
                  >
                    <FooterSubscribe color={subscribeColor} />
                  </div>
                </div>
                <div className="md:w-1/2 pl-gs">
                  <div className="h-full flush-both md:flush-none py-10 lg:py-15 bg-smoke">
                    <SiteContact data={data} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    />
  )
}

export default Footer
