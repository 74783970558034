import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import smoothscroll from 'smoothscroll-polyfill'

const titleBlock = (title, titleLink) => {
  if (title && titleLink) {
    return (
      <AniLink className="inline-block" fade to={titleLink}>
        <h1>{title}</h1>
      </AniLink>
    )
  } else if (title) {
    return <h1>{title}</h1>
  }
  return null
}

const Header = ({
  title,
  titleLink,
  color,
  toggleMenu,
  menuIsVisible,
  pageHasHero,
}) => {
  if (typeof window !== 'undefined') {
    smoothscroll.polyfill()
  }

  const [positionClass, setPositionClass] = useState(
    pageHasHero ? 'absolute' : 'fixed'
  )

  const handleMenuClick = e => {
    if (!pageHasHero) return
    if (window.scrollY <= window.innerHeight * 2 && !menuIsVisible) {
      window.scrollTo({
        top: window.innerHeight * 2,
        behavior: 'smooth',
      })
    }
  }

  const eventScroll = () => {
    if (
      window.scrollY > window.innerHeight * 2 &&
      positionClass === 'absolute'
    ) {
      setPositionClass('fixed')
    }
    if (window.scrollY <= window.innerHeight * 2 && positionClass === 'fixed') {
      setPositionClass('absolute')
    }
  }

  useEffect(() => {
    if (pageHasHero) window.addEventListener('scroll', eventScroll)
    return () => {
      if (pageHasHero) window.removeEventListener('scroll', eventScroll)
    }
  })

  return (
    <StaticQuery
      query={graphql`
        query HeaderQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div className="relative text-2xl lg:text-6xl leading-tightest uppercase">
          <div
            className={`${positionClass} h-hh w-full left-0 top-0 px-ogs z-30  bg-${color}`}
          >
            <div className="flex items-center justify-end -ml-gs h-full">
              <div className="hidden md:block w-1/2 pl-gs">
                {titleBlock(title, titleLink)}
              </div>
            </div>
          </div>

          <div
            className={`${positionClass} h-hh w-full left-0 top-0 px-ogs bg-transparent z-50 pointer-events-none`}
          >
            <div className="flex items-center justify-between -ml-gs h-full">
              <div className="md:w-12/24 pl-gs">
                {title ? (
                  <AniLink fade to="/" className="pointer-events-auto">
                    {data.site.siteMetadata.title}
                  </AniLink>
                ) : (
                  <h1>{data.site.siteMetadata.title}</h1>
                )}
              </div>
              <div className="w-10 pointer-events-auto">
                <button
                  aria-label="menu"
                  onClick={e => {
                    toggleMenu()
                    handleMenuClick(e.target)
                  }}
                  className={`header-burger ${
                    menuIsVisible ? 'header-burger--menu-is-open' : ''
                  }`}
                ></button>
              </div>
            </div>
          </div>
        </div>
      )}
    />
  )
}

export default Header
