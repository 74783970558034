import React, { useState, useEffect } from 'react'

const FooterQuotes = ({ quotes }) => {
  const [quote, setQuote] = useState()

  useEffect(() => {
    const filteredQuotes = quotes.filter(function(quote) {
      return quote.quote.html !== ''
    })

    const randomQuote =
      filteredQuotes[Math.floor(Math.random() * filteredQuotes.length)]

    setQuote(randomQuote)
  }, [quotes])

  return (
    <>
      {quote && quote.quote.html && (
        <div
          className="rte text-xl lg:text-5xl mb-8 md:mb-10 lg:mb-20"
          dangerouslySetInnerHTML={{
            __html: quote.quote.html,
          }}
        />
      )}
      {quote && quote.name.html && (
        <div
          className="rte text-sm lg:text-xl"
          dangerouslySetInnerHTML={{
            __html: quote.name.html,
          }}
        />
      )}
    </>
  )
}

export default FooterQuotes
