import React from 'react'
import Link from '../Link'

const PageHeading = ({ heading, bg, link }) => {
  if (!heading) return null
  return (
    <div
      className={`md:hidden sticky top-hh h-hh w-full flex items-center px-ogs bg-${bg} z-20`}
    >
      <h2 className="text-2xl uppercase leading-none tracking-tight">
        {link ? (
          <Link link={link} className="link">
            {heading}
          </Link>
        ) : (
          <>{heading}</>
        )}
      </h2>
    </div>
  )
}

export default PageHeading
