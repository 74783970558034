import React from 'react'

const SiteContact = ({ data, className }) => {
  return (
    <div
      className={`flex flex-col md:flex-row justify-end md:items-end h-full -ml-gs text-sm lg:text-xl ${className &&
        className}`}
    >
      <div className="md:w-4/12 pl-gs mb-6 md:mb-0">
        <ul>
          {data.prismicGlobal.data.social_menu.map((item, index) => {
            return (
              <li key={index}>
                <a
                  className="link"
                  href={item.link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.link_heading}
                </a>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="md:w-7/12 pl-gs">
        <ul className="mb-4">
          {data.prismicGlobal.data.phone_number && (
            <li>
              <a
                className="link"
                href={`tel:${data.prismicGlobal.data.phone_number}`}
              >
                {data.prismicGlobal.data.phone_number}
              </a>
            </li>
          )}
          {data.prismicGlobal.data.email_address && (
            <li>
              <a
                className="link"
                href={`mailto:${data.prismicGlobal.data.email_address}`}
              >
                {data.prismicGlobal.data.email_address}
              </a>
            </li>
          )}
        </ul>
        <div>
          {data.prismicGlobal.data.contact_address.html && (
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicGlobal.data.contact_address.html,
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default SiteContact
