import React from 'react'
import Img from 'gatsby-image'

const PageHero = ({ image, text, color, alt }) => {
  if (alt) {
    return (
      <div className={`bg-${color} px-ogs`}>
        <div className="flex flex-wrap -ml-gs">
          <div
            className={`${
              text ? '' : 'hidden md:block'
            } w-full md:w-8/24 pl-gs order-last md:order-first`}
          >
            <div
              className={`bg-${color} flush-both md:flush-left h-full flex flex-col justify-end`}
            >
              {text && (
                <div className="-ml-gs pt-25 py-5 lg:py-7.5">
                  <div
                    className="w-7/8 lg:w-6/8 pl-gs text-sm lg:text-xl leading-tight rte"
                    dangerouslySetInnerHTML={{
                      __html: text.html,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="w-full md:w-16/24 pl-gs md:pl-0 order-first md:order-last">
            <div className="flush-both md:flush-right px-0">
              {image.localFile && (
                <Img
                  fluid={image.localFile.childImageSharp.fluid}
                  alt={image.alt}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  } else if (image.localFile) {
    return <Img fluid={image.localFile.childImageSharp.fluid} alt={image.alt} />
  } else {
    return null
  }
}

export default PageHero
