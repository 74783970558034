import React from 'react'
import lottie from 'lottie-web'
import { isMobile } from 'react-device-detect'

class Hero extends React.Component {
  constructor(props) {
    super(props)
    this.container = React.createRef()
  }

  state = {
    animation: Object,
    vh: '1vh',
  }

  animation = () => {
    const data = {
      wrapper: this.container.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/hero.json',
    }
    this.setState({
      animation: lottie.loadAnimation(data),
    })
  }

  runAnimation = () => {
    let animObject = this.state.animation
    let duration = window.innerHeight
    let scrollPosition = window.scrollY
    let maxFrames = animObject.totalFrames
    let frame = (maxFrames / 100) * (scrollPosition / (duration / 100))

    if (frame > 0 && frame <= maxFrames) {
      animObject.goToAndStop(frame, true)
    } else if (frame === 0) {
      animObject.goToAndStop(0, true)
    } else if (frame > maxFrames) {
      animObject.goToAndStop(maxFrames, true)
    }
  }

  eventScroll = () => {
    this.runAnimation()
  }

  setVh = () => {
    let value
    if (isMobile) {
      value = 'var(--vh, 1vh)'
    } else {
      value = '1vh'
    }
    this.setState({
      vh: value,
    })
  }

  componentDidMount() {
    this.animation()
    if (typeof window !== 'undefined') {
      this.setVh()
    }
    window.addEventListener('scroll', this.eventScroll, false)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.eventScroll, false)
  }

  render() {
    return (
      <div
        className="bg-peach relative z-60 opacity-"
        style={{ height: `calc(${this.state.vh} * 200)` }}
      >
        <div
          style={{ height: `calc(${this.state.vh} * 100)` }}
          className="sticky top-0 gutters flex items-end p-ogs"
        >
          <div className="w-full md:w-22/24 lg:w-15/24">
            <div className="w-full" ref={this.container}></div>
          </div>
        </div>
      </div>
    )
  }
}

export default Hero
