import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { CSSTransition } from 'react-transition-group'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

import Header from '../Header'
import Menu from '../Menu'
import Footer from '../Footer'
import Hero from '../Hero'
import GridHelper from '../GridHelper'

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuIsVisible: false,
      showGridHelper: false,
    }
    this.menu = React.createRef()
  }

  setVhProperty = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  resizeEvent = () => {
    this.setVhProperty()
  }

  toggleMenu = () => {
    if (!this.state.menuIsVisible) {
      this.setState({ menuIsVisible: true })
      disableBodyScroll(this.menu.current)
    } else {
      this.setState({ menuIsVisible: false })
      enableBodyScroll(this.menu.current)
    }
  }

  componentDidMount = () => {
    this.setVhProperty()
    window.addEventListener('resize', this.resizeEvent)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resizeEvent)
    clearAllBodyScrollLocks()
  }

  render() {
    const {
      children,
      title,
      headerColor,
      quoteColor,
      subscribeColor,
      hasHero,
      hideFooter,
      titleLink,
    } = this.props

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <div className="text-base font-sans leading-normal bg-white">
            {this.state.showGridHelper && <GridHelper />}
            {hasHero && <Hero />}
            <Header
              title={title}
              color={headerColor}
              toggleMenu={this.toggleMenu}
              menuIsVisible={this.state.menuIsVisible}
              titleLink={titleLink}
              pageHasHero={hasHero}
            />
            <div ref={this.menu}>
              <CSSTransition
                in={this.state.menuIsVisible}
                timeout={700}
                classNames="menu-slide"
              >
                <Menu />
              </CSSTransition>
            </div>
            {children}
            {!hideFooter && (
              <Footer quoteColor={quoteColor} subscribeColor={subscribeColor} />
            )}
          </div>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
