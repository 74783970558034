import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Link from '../Link'
import SiteContact from '../Site/SiteContact'

const Menu = () => {
  return (
    <StaticQuery
      query={graphql`
        query MenuQuery {
          prismicGlobal {
            data {
              contact_address {
                html
              }
              email_address
              phone_number
              primary_menu {
                link_heading
                link {
                  url
                  uid
                  link_type
                }
              }
              social_menu {
                link {
                  url
                }
                link_heading
              }
            }
          }
        }
      `}
      render={data => (
        <div className="fixed top-0 right-0 w-full z-40 md:w-1/2 menu-slide-initial">
          <div className="bg-peach overflow-y-auto h-screen-calc px-2x-ogs pt-hh pb-2x-ogs">
            <div className="relative flex flex-col justify-between min-h-full">
              <ul className="pt-25 md:pt-40 lg:pt-30 pb-2x-ogs">
                {data.prismicGlobal.data.primary_menu.map((item, index) => {
                  return (
                    <li
                      className="text-8xl lg:text-9xl tracking-tight leading-tightest uppercase"
                      key={index}
                    >
                      <Link
                        className="link"
                        link={item.link}
                        text={item.link_heading}
                      />
                    </li>
                  )
                })}
              </ul>
              <SiteContact
                data={data}
                className="md:justify-start leading-tight"
              />
            </div>
          </div>
        </div>
      )}
    />
  )
}

export default Menu
