import React from 'react'

const GridHelper = () => {
  return (
    <div className="grid-helper">
      {[...Array(24)].map((item, index) => {
        return (
          <div
            key={index}
            className={`grid-helper__item ${
              index > 11 ? 'hidden md:block' : ''
            }`}
          >
            <div className="grid-helper__item-inner"></div>
          </div>
        )
      })}
    </div>
  )
}

export default GridHelper
