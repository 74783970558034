import React, { useState, useRef, useEffect } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import config from '../../../config/site'
import smoothscroll from 'smoothscroll-polyfill'

const Success = () => (
  <h4 className="text-2xl lg:text-5xl mb-8">
    Thanks, you’ve been added to our mailing list.
  </h4>
)

const FooterSubscribe = ({ color }) => {
  if (typeof window !== 'undefined') {
    smoothscroll.polyfill()
  }

  const mailChimpGroup = config.mailChimp.groupId
  const container = useRef(null)
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const getOffset = element => {
    const rect = element.getBoundingClientRect()
    return {
      top: rect.top + window.pageYOffset,
      left: rect.left + window.pageXOffset,
    }
  }

  useEffect(() => {
    if (window.location.search === '?sign-up') {
      setTimeout(() => {
        window.scrollTo({
          top: getOffset(container.current).top,
          behavior: 'smooth',
        })
      }, 500)
    }
  }, [])

  const resetErrorState = () => {
    if (error) setError(false)
    if (errorMsg) setErrorMsg('')
  }
  const formatErrorMessage = message => {
    return message.replace('0 - ', '')
  }

  const handleSubmit = async e => {
    try {
      setSubmitting(true)
      e.preventDefault()
      const { firstName, lastName, email, group } = e.target
      const formData = {}
      if (firstName && firstName.value) formData.FNAME = firstName.value
      if (lastName && lastName.value) formData.LNAME = lastName.value
      if (mailChimpGroup) {
        if (group.value === 'all') {
          const group1 = group[0].value
          const group2 = group[1].value
          formData[`group[${mailChimpGroup}][${group1}]`] = group1
          formData[`group[${mailChimpGroup}][${group2}]`] = group2
        } else {
          formData[`group[${mailChimpGroup}][${group.value}]`] = group.value
        }
      }
      const { result, msg } = await addToMailchimp(email.value, formData)
      if (result === 'success') {
        resetErrorState()
        return setSuccess(true)
      }
      if (result === 'error') throw new Error(msg)
    } catch (error) {
      if (error && error.constructor === Error) {
        if (error.message) setErrorMsg(formatErrorMessage(error.message))
        return setError(true)
      }
    } finally {
      setSubmitting(false)
    }
  }
  if (success) {
    return <Success />
  } else {
    return (
      <div ref={container}>
        <h4 className="text-2xl lg:text-5xl mb-8">
          Sign up to our mailing list to receive:
        </h4>
        <form className="text-sm lg:text-xl" onSubmit={handleSubmit}>
          <label htmlFor="radio-one" className="radio">
            Advice, inspiration &amp; events to help you develop as a leader
            <input
              id="radio-one"
              type="radio"
              name="group"
              value="1"
              defaultChecked="checked"
            />
            <div className="radio__indicator"></div>
          </label>
          <label htmlFor="radio-two" className="radio">
            Insights to help bring more gender balance to your organisation
            <input id="radio-two" type="radio" name="group" value="2" />
            <div className="radio__indicator"></div>
          </label>
          <div className="mb-8">
            <label htmlFor="radio-third" className="radio">
              Both
              <input id="radio-third" type="radio" name="group" value="all" />
              <div className="radio__indicator"></div>
            </label>
          </div>
          <div className="-ml-gs">
            <div className="md:w-11/12 xl:w-7/12 pl-gs">
              <div className="mb-3">
                <input
                  className="input placeholder-black"
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  aria-label="First Name"
                  autoComplete="given-name"
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  className="input placeholder-black"
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  aria-label="Last Name"
                  autoComplete="family-name"
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  className="input placeholder-black"
                  type="email"
                  name="email"
                  placeholder="Email"
                  aria-label="Email"
                  autoComplete="email"
                  required
                />
              </div>
              <button
                className={`button w-full text-${color ? color : 'green'}`}
                type="submit"
                value="Submit"
                aria-label="Submit"
                disabled={submitting}
              >
                Submit
              </button>
              {error && (
                <div
                  className="mt-3"
                  dangerouslySetInnerHTML={{
                    __html: errorMsg,
                  }}
                ></div>
              )}
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default FooterSubscribe
