module.exports = {
  site: {
    title: `The Female Career`,
    description: `We coach and inspire talented women of Aotearoa`, // Used in the meta description
    siteUrl: `https://www.thefemalecareer.com`, // No trailing slash
    image: `og-image.jpg`, // Relative to the folder `./static/`
  },
  manifest: {
    shortName: `TFC`, // Under 12 characters.
    bgColor: `#ff006a`,
    themeColor: `#ff006a`,
    icon: `favicon.svg`, // Relative to the folder `./src/images/`
  },
  google: {
    tagmanager: {
      id: `GTM-PBFMN7S`,
    },
  },
  prismic: {
    repository: `thefemalecareer001`,
  },
  mailChimp: {
    groupId: 12541,
    endpoint:
      'https://thefemalecareer.us19.list-manage.com/subscribe/post?u=2bc927110fa686cba9047b3a9&amp;id=e8cf116176',
  },
  facebook: {
    pixelId: 217100656916662,
  },
}
