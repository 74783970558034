import React from 'react'

const FooterCta = ({ cta, email }) => {
  return (
    <div className="md:w-3/4 lg:w-11/16 text-4xl md:text-5xl lg:text-8xl leading-tighter tracking-tight lg:tracking-tighter">
      {cta && <div>{cta}</div>}
      {email && (
        <a className="link-alt" href={`mailto:` + email}>
          {email}
        </a>
      )}
    </div>
  )
}

export default FooterCta
