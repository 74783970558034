import React from 'react'

const PageLead = ({ lead, color, alt, isIndexPage }) => {
  return (
    <div className={`px-ogs ${!isIndexPage ? 'pt-hh' : ''}`}>
      <div className="-ml-gs flex">
        <div className={`${alt ? 'md:w-1/2' : 'md:w-16/24'} w-11/12 pl-gs`}>
          <div className="-ml-gs">
            <div
              className={`${
                alt ? 'md:w-11/12' : 'md:w-13/16'
              } w-full pl-gs pt-25 md:pt-40 lg:pt-30 pb-5 lg:pb-7.5`}
            >
              <div
                className="text-3xl lg:text-6xl leading-tight rte"
                dangerouslySetInnerHTML={{
                  __html: lead,
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={`${
            alt ? 'w-1/2 pl-gs/2' : 'w-8/24 pl-gs'
          } hidden md:block`}
        >
          <div className={`flush-right h-full bg-${color}`}></div>
        </div>
      </div>
    </div>
  )
}

export default PageLead
