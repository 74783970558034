import React from 'react'
import Link from '../Link'

const PageStickyBar = ({ headings, links, alt }) => {
  return (
    <div
      className={`hidden md:block top-hh h-hh sticky px-ogs z-20 translate-3d ${
        alt ? 'bg-grey' : 'bg-white'
      }`}
    >
      <div className="flex items-center h-full -ml-gs">
        {!links &&
          headings.map((item, index) => {
            return (
              <div
                key={index}
                className={`pl-gs pt-px ${
                  headings.length === 3 ? 'w-1/3' : 'w-1/2'
                }`}
              >
                <h4 className="text-2xl lg:text-6xl tracking-tighter uppercase">
                  {item}
                </h4>
              </div>
            )
          })}
        {links &&
          links.map((item, index) => {
            return (
              <div
                key={index}
                className={`pl-gs pt-px ${
                  links.length === 3 ? 'w-1/3' : 'w-1/2'
                }`}
              >
                <h4 className="text-2xl lg:text-6xl tracking-tighter uppercase">
                  <Link
                    link={item.link}
                    className={`link sticky-bar-link-${index}`}
                  >
                    {item.heading}
                  </Link>
                </h4>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default PageStickyBar
