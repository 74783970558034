import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const Link = ({ children, link, text, className }) => {
  if (!(link && link.url)) {
    return null
  }

  if (link.link_type === 'Document') {
    return (
      <AniLink to={link.url} className={className} fade>
        {children ? children : text}
      </AniLink>
    )
  } else {
    return (
      <a href={link.url} className={className}>
        {children ? children : text}
      </a>
    )
  }
}

export default Link
